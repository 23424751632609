import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
  to {
    transform: rotate(360deg);

  }
`

export const Wrapper = styled.div`
  position: relative;
`

export const Rotate = styled.div`
  position: relative;
  svg {
    animation: ${rotate} 800ms linear infinite;
  }
`
