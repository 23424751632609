import React from 'react'

import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { IconType } from 'react-icons'

import IconLoader from 'components/loader/icon'

type IconPropsType = {
  size?: number
  icon?: React.ComponentType<AntdIconProps> | IconType
  color?: string
  isLoading?: boolean
  onClick?: () => void
  className?: string
  css?: string
}

const Icon = ({ icon: IconComponent, size, isLoading, color, ...props }: IconPropsType): JSX.Element => {
    if (isLoading) return <IconLoader size={size} {...props} color={color} />
    if (!IconComponent) return <span />

    return <IconComponent size={size} color={color} {...props} />
}

export default Icon
