import React from 'react'

import { BiLoaderAlt } from '@react-icons/all-files/bi/BiLoaderAlt'

import { Rotate, Wrapper } from './styles'

const IconLoader = ({ ...props }): JSX.Element => {
    return (
        <Wrapper>
            <Rotate>
                <BiLoaderAlt {...props} />
            </Rotate>
        </Wrapper>
    )
}

export default IconLoader
